@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
/* BrightCove Video Styling */

.video-js {
	.vjs-poster {
		// Prefer transparent to white in case it is not on a white background
		background-color: transparent;
	}
	.vjs-volume-level {
		background-color: $ch-corporate-red !important;
		background: $ch-corporate-red !important;
	}
	&:hover > .vjs-big-play-button {
		background-color: $ch-corporate-red !important;
		background: $ch-corporate-red !important;
		background-image: $ch-corporate-red !important;
	}
}

.vjs {
	&-title-bar {
		display: none;
	}

	&-big-play-button:active,
	&-big-play-button:hover,
	&-big-play-button:focus {
		background: $ch-corporate-red !important;
		background-color: $ch-corporate-red !important;
		background-image: $ch-corporate-red !important;
	}

	&-play-progress {
		background-color: $ch-corporate-red !important;
		background: $ch-corporate-red !important;
	}

	&-poster {
		img{
			transform: none !important;
		}
	}

	&-picture-in-picture-control {
		display: none !important;
	}

	&-playback-rate {
		display: none !important;
	}

	&-selected {
		background-color: transparent !important;
		background: transparent !important;
	}

	&-menu-item {
		background-color: $ch-corporate-red !important;
		background: $ch-corporate-red !important;
		color: #2B333F !important;

		&:active {
			background-color: transparent !important;
			background: transparent !important;
			color: $ch-corporate-white !important;
		}

		&:hover {
			color: $ch-corporate-white !important;
		}
	}
}

.slick-track {
	height: 100% !important;

	& > div {
		height: 100% !important;
	}

	& > div > div {
		height: 100% !important;
		width: 100% !important;
	}
}

.slice-slide {
	 & > div > div {
		 height: 100% !important;
	 }
	 & > div > div > div {
		 height: 100% !important;
	 }
}

.bc-player-default_default {
	.vjs-menu-button-popup .vjs-menu {
		.vjs-menu-item .vjs-selected {
			background-color: $ch-corporate-red !important;
		}

		.vjs-menu-item, .vjs-menu-title {
			background:  #151b17 !important;
		}
	}
}

/* BrightCove Video Styling */