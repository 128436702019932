@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
/* Custom styles for the carousel container */
.carousel-container {
	max-width: 800px; /* Adjust this based on your design */
	margin: 20px auto; /* Center the carousel */
	position: relative;
	overflow: hidden;

	@include ch-break-down("lg") {
		max-width: 550px;
	}
	@include ch-break-down("xs") {
		max-width: 350px;
	}
}

/* Custom styles for each slide */
.carousel-container .slick-slide {
	display: flex !important; /* Ensure slides are displayed as flex */
	justify-content: center; /* Center content inside the slide */
	align-items: center; /* Center content vertically */
}

.carousel-container .slick-slide img {
	width: 100%; /* Ensure images take up the full width */
	height: 100%; /* Maintain the aspect ratio */
	margin: 0 !important;
	object-fit: cover;
}

/* Custom arrow styles */
.carousel-container .slick-prev,
.carousel-container .slick-next {
	z-index: 1;
	width: 36px;
	height: 60px;
	background-color: #ed2939; /* Red background for arrows */
	border-radius: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.carousel-container .slick-prev:before,
.carousel-container .slick-next:before {
	font-size: 0; /* Remove default font size */
	color: white; /* White arrow color for better visibility */
	content: ""; /* Remove default content */
	border: solid white;
	border-width: 0 4px 4px 0; /* Increase border width to make arrows larger */
	display: inline-block;
	padding: 8px; /* Increase padding to fill the space */
	opacity: 1;
}
.carousel-container .slick-prev:before {
	transform: rotate(135deg); /* Left arrow */
	-webkit-transform: rotate(135deg);
	margin-left: 10px; /* Add margin to move the arrow inward */
}

.carousel-container .slick-next:before {
	transform: rotate(-45deg); /* Right arrow */
	-webkit-transform: rotate(-45deg);
	margin-left: 4px;
}
/* Positioning the arrows */
.carousel-container .slick-prev {
	left: 0;
}

.carousel-container .slick-next {
	right: 0;
}
